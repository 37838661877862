import React, { useState, useEffect } from 'react';

const Grammar = ({ selectedGrammar }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    if (selectedGrammar) {
      fetch('/grammar.json') // Fetch the JSON file
        .then(response => response.json())
        .then(data => {
          setArticles(data[selectedGrammar] || []);
        })
        .catch(error => console.error('Error fetching grammar data:', error));
    }
  }, [selectedGrammar]);

  return (
  
    <div className ='mt-4'>
      <h2>{selectedGrammar} Grammar articles</h2>
      <ul className="list-group mt-4">
        {articles.map((entry, index) => (
          <>
          <li className='list-group-item mb-4' key={index}>
          <h3>{entry.title}</h3> 
          <p>{entry.body}</p>
          </li>
          </>
        ))}
        </ul>
    </div>
  );


};

export default Grammar;
