import React, { useState, useEffect } from 'react';

const Dictionary = ({ selectedCategory }) => {
  const [words, setWords] = useState([]);

  useEffect(() => {
    if (selectedCategory) {
      fetch('/dictionary.json') // Fetch the JSON file
        .then(response => response.json())
        .then(data => {
          setWords(data[selectedCategory] || []);
        })
        .catch(error => console.error('Error fetching dictionary data:', error));
    }
  }, [selectedCategory]);

  return (
    <div className='mt-4'>
      <h2>{selectedCategory} Dictionary</h2>
      <ul className="list-group mt-4">
        {words.map((entry, index) => (
          <li className='list-group-item mb-4' key={index}>
            <strong>{entry.eng}</strong>: {entry.tur}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dictionary;
