import React, { useState, useEffect } from "react";
import FlashcardList from "./FlashcardList";

//functional component, receives 3 props
// array of all flashcards, array of selected categories & grammars
//passed from parent component App
const Quiz = ({
  selectedCategories,
  selectedGrammars,
  maxCards,
}) => {
  //state management
  //keep track of which card currently displayed, starts at 0
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  //first card is not flipped
  const [isFlipped, setIsFlipped] = useState(false);
  const [shuffledFlashcards, setShuffledFlashcards] = useState([]);
  const [flashcards, setFlashcards] = useState([]); // State to store fetched flashcards
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [gifUrl, setGifUrl] = useState(""); // Store the GIF URL

  // shuffle function
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };




 // Fetch the flashcards and process them based on combinations
 useEffect(() => {

    // Function to fetch flashcards based on all category-grammar combinations
    const fetchFlashcardsForCombinations = async () => {
      let allFlashcards = [];
  
      for (const category of selectedCategories) {
        for (const grammar of selectedGrammars) {
          const categoryLower = category.toLowerCase();
          const grammarLower = grammar.toLowerCase();
  
          try {
            // Dynamically construct the file path based on the category and grammar
            const response = await fetch(`/flashcards/${grammarLower}_${categoryLower}.json`);
            if (response.ok) {
              const data = await response.json();
              allFlashcards = [...allFlashcards, ...data];  // Append the fetched flashcards to the list
            } else {
              console.error(`Failed to load flashcards for ${categoryLower}_${grammarLower}.json`);
            }
          } catch (error) {
            console.error(`Error fetching flashcards for ${categoryLower}_${grammarLower}.json:`, error);
          }
        }
      }
      return allFlashcards;
    };


  const loadFlashcards = async () => {
    if (selectedCategories.length > 0 && selectedGrammars.length > 0) {
      const allFlashcards = await fetchFlashcardsForCombinations();
      setFlashcards(allFlashcards);
    }
  };

  loadFlashcards();
}, [selectedCategories, selectedGrammars]);

  // Filter, shuffle, and limit the flashcards after fetching
  useEffect(() => {
    // Shuffle and limit the flashcards to maxCards
    setShuffledFlashcards(shuffleArray(flashcards).slice(0, maxCards));
  }, [flashcards, selectedCategories, selectedGrammars, maxCards]);

  //string of progress
  const progressString = `${currentCardIndex + 1} / ${
    shuffledFlashcards.length
  }`;

  //function that moves onto next card
  const handleNextCard = () => {
    //if current card index less than total

    if (currentCardIndex < shuffledFlashcards.length - 1) {
      // then increment index by 1
      setCurrentCardIndex((prevIndex) => prevIndex + 1);
    } else {
      getAndDisplayRandomCelebrateGIF();
    }
    //the next card should not be flipped
    setIsFlipped(false);
  };

  const getAndDisplayRandomCelebrateGIF = () => {
    setIsModalOpen(true);
    const apiKey = "AIzaSyDFs1KVPX2F_96uezCWjiKCu5X4fBm69og";
    const url = `https://tenor.googleapis.com/v2/search?key=${apiKey}&limit=1&contentFilter=high&random=true&q=celebratecute&media_filter=gif`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.results.length > 0) {
          const gifUrl = data.results[0].media_formats.gif.url;
          setGifUrl(gifUrl);
          
        } else {
          console.error("No gifs found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching gifs:", error);
      });
    setCurrentCardIndex(0);
  };

  const Modal = () => {
    return (
      <>
        {isModalOpen && (
          <div className="container d-flex justify-content-center align-items-center mb-4 rounded text-center">
            <div className="gif-box text-center p-4 bg-light rounded shadow">
              <p>You're done!</p>
              <div className="d-flex flex-column align-items-center">
                <img
                  src={gifUrl}
                  alt="celebrate!"
                  className="img-fluid"
                  style={{ maxHeight: "200px", width: "auto" }} // Responsive image with limited height
                />
                <img
                  src="https://www.gstatic.com/tenor/web/attribution/via_tenor_logo_grey.png"
                  alt="via Tenor"
                  className="img-fluid"
                  style={{
                    position: "relative",
                    bottom: "7px",
                    maxHeight: "7px",
                    width: "auto",
                    backgroundColor: "white",
                    opacity: 0.5,
                  }}
                />
                <button
                  className="btn btn-secondary m-1"
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  ↺ Repeat
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const BackNext = () => {
    return (
      <>
        {!isModalOpen && (
          <div className="container">
            <button
              className="btn btn-secondary m-1"
              onClick={() => setIsFlipped(!isFlipped)}
            >
              ↺ Flip
            </button>

            <button className="btn btn-secondary m-1" onClick={handleNextCard}>
              → Next
            </button>

            <div className="progress-string">
              <span>{progressString}</span>
              <div style={{ width: `${progressString}` }}></div>
            </div>
          </div>
        )}
      </>
    );
  };

  //render the ui
  return (
    <div className="quiz">
      <>
        <Modal />
        <FlashcardList
          //   Pass props to flashcardlist
          // the card list
          shuffledFlashcards={shuffledFlashcards}
          // the current card index
          currentCardIndex={currentCardIndex}
          //if it's flipped or not
          isFlipped={isFlipped}
          // the function allowing the child to update if it's flipped
          setIsFlipped={setIsFlipped}
          isModalOpen={isModalOpen}
        />

        <BackNext />
      </>
    </div>
  );
};

export default Quiz;
