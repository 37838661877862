import React, { useState } from "react"; //useState keeps tracks of what things are selected
import CategorySelector from "./CategorySelector";
import Quiz from "./Quiz";

import "./App.css";

const App = () => {
  //state variables

  ////////////////////
  //stores list of grammars the user has selected, starts as an empty array
  const [selectedGrammars, setSelectedGrammars] = useState([]);

  const handleGrammarChange = (a) => {
    // make a copy to avoid modifying original list
    const newGrammars = [...selectedGrammars];
    if (a.target.checked) {
      // if checked, grammar is added to the list
      newGrammars.push(a.target.value);
    } else {
      // if unchecked, remove the value
      newGrammars.splice(newGrammars.indexOf(a.target.value), 1);
    }
    // update the list
    setSelectedGrammars(newGrammars);
  };

  ////////////////////
  //stores list of categories the user has selected, starts as an empty array
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCategoryChange = (e) => {
    const newCategories = [...selectedCategories];
    if (e.target.checked) {
      newCategories.push(e.target.value);
    } else {
      newCategories.splice(newCategories.indexOf(e.target.value), 1);
    }
    setSelectedCategories(newCategories);
  };

  ////////////////////
  const [maxCards, setMaxCards] = useState(5);

  const handleMaxCardsChange = (m) => {
    setMaxCards(m.target.value);
  };

  ////////////////////

  //Tracks whether the quiz is currently active or not. Initially set to false
  const [isQuizActive, setIsQuizActive] = useState(false);
  const [quizStartKey, setQuizStartKey] = useState(0); // State to trigger reshuffle

  const toggleQuiz = () => {
    // Check if at least one category and one grammar are selected
    if (selectedCategories.length > 0 && selectedGrammars.length > 0) {
      setQuizStartKey((prevKey) => prevKey + 1);
      setIsQuizActive(!isQuizActive);
    } else {
      alert(
        "Please select at least one category and one grammar to start the quiz."
      );
    }
  };

  /////////

  //render the component

  return (
    <div className="app">
      <h1>The Turkish Learner</h1>

      {/* if isQuizActive is true, show Quiz component */}
      {isQuizActive ? (
        <Quiz
          quizStartKey={quizStartKey} // Use key to force re-render and reshuffle
          // received flashcard data, selectedCats & grams as props
          selectedCategories={selectedCategories}
          selectedGrammars={selectedGrammars}
          maxCards={maxCards}
          // else show the CategorySelector component
        />
      ) : (
        <CategorySelector
          // receives four props:
          //array of currently selected categories
          selectedCategories={selectedCategories}
          //function passed as a prop, handles when user selects/deselects
          //parent component passes function to child component via props
          //child component can call the function to communicate back or trigger actions
          //in the parent.
          //often used when child component needs to influence parent
          //parent defines function. passes as a prop. child calls function. parent state is updated.
          handleCategoryChange={handleCategoryChange}
          selectedGrammars={selectedGrammars}
          handleGrammarChange={handleGrammarChange}
          maxCards={maxCards}
          handleMaxCardsChange={handleMaxCardsChange}
          setSelectedCategories={setSelectedCategories}
          setSelectedGrammars={setSelectedGrammars}
        />
      )}

      {/* button to stop-start the quix, toggles the quiz state */}
      <div className="d-flex justify-content-center mb-4">
        <button
          className="btn btn-primary col-4"
          onClick={toggleQuiz}
        >
          {isQuizActive ? "Finish" : "Start flashcards"}
        </button>
      </div>
    </div>
  );
};

export default App;
