import React from "react";
import "./Flashcard.css";

// functional component with 3 props
// question , answer and if the card is flipped
// these are passed from parent FlashcardList
const Flashcard = ({ question, answer, isFlipped }) => {
  return (
    // for styling
    <div className="card my-3 shadow-sm">
      {/* question always visible */}
      <div className="card-body text-center">
        <div className="card-title">{question}</div>
        <div className="card-text">
          {/* if isflipped = true, show answer */}
          {isFlipped ? answer : <span>___</span>}
        </div>
      </div>
    </div>
  );
};

export default Flashcard;
