import React, { useState } from "react";
import Dictionary from "./Dictionary"; // Import your Dictionary component
import Grammar from "./Grammar";
import "./CategorySelector.css";

//accepts four props: the two arrays & the two functions
const CategorySelector = ({
  selectedCategories,
  selectedGrammars,
  handleCategoryChange,
  handleGrammarChange,
  maxCards,
  handleMaxCardsChange,
  setSelectedCategories,
  setSelectedGrammars
}) => {
  const categories = [{ catval: "basic", catlabel: "Basic" }];

  const grammars = [
    { gramval: "present_continuous", gramlabel: "I'm doing" },
    { gramval: "aorist", gramlabel: "I do" },
    { gramval: "past", gramlabel: "I did" },
    { gramval: "past_progressive", gramlabel: "I was doing" },
    { gramval: "embedded_question", gramlabel: "I know what you..." },
    { gramval: "reported_speech", gramlabel: "I heard that you..." },
    { gramval: "in_order_to", gramlabel: "In order to..." },
    { gramval: "instead", gramlabel: "Instead of..." },
    // { gramval: "possessive", gramlabel: "Possessive" },
    // ... other grammars
  ];

  const [selectedCategoryForDictionary, setSelectedCategoryForDictionary] =
    useState(null);
  const [selectedGrammarForGrammars, setSelectedGrammarForGrammars] =
    useState(null);

  const handleViewDictionary = (category) => {
    setSelectedCategoryForDictionary(category);
  };

  const handleViewGrammar = (grammar) => {
    setSelectedGrammarForGrammars(grammar);
  };

  const handleBackToSelector = () => {
    setSelectedCategoryForDictionary(null);
    setSelectedGrammarForGrammars(null);
  };

// Handler to deselect all categories and grammars
const handleDeselectAll = () => {
  // Deselect all categories
  const newSelectedCategories = selectedCategories.filter(catval =>
    categories.every(({ catval: categoryValue }) => categoryValue !== catval)
  );
  setSelectedCategories(newSelectedCategories); // Update categories all at once

  // Deselect all grammars
  const newSelectedGrammars = selectedGrammars.filter(gramval =>
    grammars.every(({ gramval: grammarValue }) => grammarValue !== gramval)
  );
  setSelectedGrammars(newSelectedGrammars); // Update grammars all at once
};

// Handler to select all categories
const handleSelectAllCats = () => {
  // Get all category values (catval)
  const allCategoryValues = categories.map(({ catval }) => catval);
  
  // Update the state at once with all categories selected
  setSelectedCategories(allCategoryValues);
};

// Handler to select all grammars
const handleSelectAllGrams = () => {
  // Get all grammar values (gramval)
  const allGrammarValues = grammars.map(({ gramval }) => gramval);

  // Update the state at once with all grammars selected
  setSelectedGrammars(allGrammarValues);
};


  if (selectedCategoryForDictionary) {
    return (
      <div className="dictionary-view">
        <button
          className="btn btn-outline-secondary mt-3"
          onClick={handleBackToSelector}
        >
          Back to Categories
        </button>
        <Dictionary selectedCategory={selectedCategoryForDictionary} />
      </div>
    );
  }

  if (selectedGrammarForGrammars) {
    return (
      <div className="grammar-view">
        <button
          className="btn btn-outline-secondary mt-3"
          onClick={handleBackToSelector}
        >
          Back to Categories
        </button>
        <Grammar selectedGrammar={selectedGrammarForGrammars} />
      </div>
    );
  }

  //render the component
  return (
    <div className="container category-selector mt-5">
      <div className="row">
        {/* Vocabulary column */}
        <div className="col-md-6">
          <h2>Vocabulary</h2>
          <div className="scrollable-list mb-5">
            {categories.map(({ catval, catlabel }) => (
              <div
                key={catval}
                className={`col-12 col-md-6 mb-3 card ${
                  selectedCategories.includes(catval)
                    ? "card-selected"
                    : "card-unselected"
                }`}
              >
                <div
                  className="card h-100 p-3 d-flex align-items-center"
                  onClick={() => {
                    handleCategoryChange({
                      target: {
                        value: catval,
                        checked: !selectedCategories.includes(catval),
                      },
                    });
                  }}
                  style={{ cursor: "pointer" }} // Change cursor to pointer
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={catval}
                        value={catval}
                        checked={selectedCategories.includes(catval)}
                        readOnly
                      />
                      <label className="form-check-label ms-2" htmlFor={catval}>
                        {catlabel} {/* Use the label for display */}
                      </label>
                    </div>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the card click
                        handleViewDictionary(catval);
                      }}
                    >
                      ⓘ
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Grammar column */}
        <div className="col-md-6">
          <h2>Grammar</h2>
          <div className="scrollable-list mb-5">
            {grammars.map(({ gramval, gramlabel }) => (
              <div
                key={gramval}
                className={`col-12 col-md-6 mb-3 card ${
                  selectedGrammars.includes(gramval)
                    ? "card-selected"
                    : "card-unselected"
                }`}
              >
                <div
                  className="card h-100 p-3 d-flex align-items-center"
                  onClick={() => {
                    handleGrammarChange({
                      target: {
                        value: gramval,
                        checked: !selectedGrammars.includes(gramval),
                      },
                    });
                  }}
                  style={{ cursor: "pointer" }} // Change cursor to pointer
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={gramval}
                        value={gramval}
                        checked={selectedGrammars.includes(gramval)}
                        readOnly
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor={gramval}
                      >
                        {gramlabel} {/* Use the label for display */}
                      </label>
                    </div>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the card click
                        handleViewGrammar(gramval);
                      }}
                    >
                      ⓘ
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Max Cards Slider */}
        <div className="mb-4">
          <input
            type="range"
            className="form-range"
            min="5"
            max="20"
            step="5"
            value={maxCards}
            onChange={handleMaxCardsChange}
          />
          <p>Max Cards: {maxCards}</p>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-center mb-4">
          <button
            className="btn btn-outline-secondary col-12 col-md-4 mb-2 mb-md-0"
            onClick={handleSelectAllCats}
          >
            Select all vocabulary sets
          </button>
          <button
            className="btn btn-outline-secondary col-12 col-md-4 mb-2 mb-md-0 mx-md-2"
            onClick={handleSelectAllGrams}
          >
            Select all grammar categories
          </button>
          <button
            className="btn btn-outline-secondary col-12 col-md-4"
            onClick={handleDeselectAll}
          >
            Un-select all
          </button>
        </div>
      </div>
    </div>
  );
};

//export makes it available to other parts of the application
export default CategorySelector;
