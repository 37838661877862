import React from "react";
//imports child component, each flashcard is a separate component
import Flashcard from "./Flashcard";

//functional component, receives props from its parent Quiz
const FlashcardList = ({
  // filtered array
  shuffledFlashcards,
  //index of the currently active flashcard
  currentCardIndex,
  // whether the flashcard is flipped or not
  isFlipped,
  //function to update the isFlipped state
  setIsFlipped,
  isModalOpen,
}) => {
  const currentFlashcard = shuffledFlashcards[currentCardIndex];
  return (
   
    <div className="flashcard-list">
      {/* checks if currentFlashcard exists, wull render nothing if null */}
      {!isModalOpen && currentFlashcard && (
        // if exists, render the flashcard component
        <Flashcard
          //pass props to the Flashcard component
          //question = question of currentCardIndex of filtered array
          question={currentFlashcard.q}
          answer={currentFlashcard.a}
          isFlipped={isFlipped}
          //pass the function to let child component flip the card
          setIsFlipped={setIsFlipped}
        />
      )}
    </div>
  );
};

export default FlashcardList;
